import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { ApiObservableService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';
import { Participated } from '@yuno/api/interface';

import {
	PARTICIPATION_ARCHIVED,
	PARTICIPATION_ONHOLD,
	PARTICIPATION_PUBLISHED,
	PARTICIPATION_SEND_ANSWER,
	PARTICIPATION_UPDATE_ANSWER,
	PARTICIPATION_UPDATE_REACTION,
	ParticipatedAnswered,
	ParticipatedArchived,
	ParticipatedOnHold,
	ParticipatedPublished,
	ParticipatedUpdateAnswer,
	ParticipatedUpdateReaction
} from './utils';

@Injectable({
	providedIn: 'root'
})
export class PostService {
	private readonly api = inject(ApiObservableService);
	private readonly graphql = inject(GraphQLService);
	private readonly message = inject(MessageService);

	getPosts(appId: string, modelId: string): Observable<Participated[]> {
		return this.api
			.get<Participated[]>(`participate/post/${appId}/posts/${modelId}`)
			.pipe(take(1));
	}

	onPublish(id: string, appId: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedPublished>({
				mutation: PARTICIPATION_PUBLISHED,
				variables: {
					_id: id,
					appId
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationPublished) {
						throw new Error('Not published');
					}

					this.message.sendToast(`Successfully published!`, 'success');

					return data.data.setParticipationPublished;
				}),
				take(1)
				// catchError(error => {
				// message.sendToast(`Error publishing!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}

	sendAnswer(id: string, appId: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedAnswered>({
				mutation: PARTICIPATION_SEND_ANSWER,
				variables: {
					_id: id,
					appId
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationAnswered) {
						throw new Error('Not answered');
					}

					this.message.sendToast(`Successfully send the answer!`, 'success');

					return data.data.setParticipationAnswered;
				}),
				take(1)
				// catchError(error => {
				// 	// this.message.sendToast(`Failed to send the answer!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}

	updateAnswer(id: string, appId: string, content: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedUpdateAnswer>({
				mutation: PARTICIPATION_UPDATE_ANSWER,
				variables: {
					_id: id,
					appId,
					content
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationUpdateAnswer) {
						throw new Error('Not answered');
					}

					this.message.sendToast(`Successfully updated answer!`, 'success');

					return data.data.setParticipationUpdateAnswer;
				}),
				take(1)
				// catchError(error => {
				// 	// this.message.sendToast(`Error updating answer!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}

	updateReaction(id: string, appId: string, content: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedUpdateReaction>({
				mutation: PARTICIPATION_UPDATE_REACTION,
				variables: {
					_id: id,
					appId,
					content
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationUpdateReaction) {
						throw new Error('Not answered');
					}

					this.message.sendToast(`Successfully updated reaction!`, 'success');

					return data.data.setParticipationUpdateReaction;
				}),
				take(1)
				// catchError(error => {
				// 	// this.message.sendToast(`Error updating reaction!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}

	onHold(id: string, appId: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedOnHold>({
				mutation: PARTICIPATION_ONHOLD,
				variables: {
					_id: id,
					appId
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationOnHold) {
						throw new Error('Not put onhold');
					}

					this.message.sendToast(`Successfully put on hold!`, 'success');

					return data.data.setParticipationOnHold;
				}),
				take(1)
				// catchError(error => {
				// 	// this.message.sendToast(`Error putting on hold!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}

	toArchive(id: string, appId: string): Observable<Participated> {
		return this.graphql
			.mutate<ParticipatedArchived>({
				mutation: PARTICIPATION_ARCHIVED,
				variables: {
					_id: id,
					appId
				}
			})
			.pipe(
				map(data => {
					if (!data.data?.setParticipationArchived) {
						throw new Error('Not archived');
					}

					this.message.sendToast(`Successfully moved to archived!`, 'success');

					return data.data.setParticipationArchived;
				}),
				take(1)
				// catchError(error => {
				// message.sendToast(`Error moving to archived!`, 'error');
				// 	return of(participationsActions.setOnHoldFailure({ error }));
				// })
			);
	}
}
